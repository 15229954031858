<template>

  <div>
    <b-card>
      <b-card-text />
      <ol>
        <li>Administratorem danych osobowych uczniów jest prowadzący – Sonia Radosz.</li>
        <li>Dane osobowe uczniów będą przetwarzane przez prowadzącego zgodnie z ustawą z dnia 29 sierpnia 1997 r. o ochronie danych osobowych (Dz.U.2016.922 j.t.).</li>
        <li>Podanie danych osobowych jest dobrowolne, jednak konieczne do celów związanych z realizacją kursu.</li>
        <li>Wyrażenie zgody na przetwarzanie danych osobowych do celów marketingowych nie jest obligatoryjne.</li>
        <li>Uczniom przysługuje prawo dostępu do treści jego danych osobowych oraz ich poprawiania, zmiany, uzupełniania i usuwania.</li>
        <li>Uczeń wyraża zgodę na publikowanie swojego wizerunku przez prowadzącego w celach m.in. promocji</li>
      </ol>
    </b-card>
  </div>

</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {

    }
  },
}
</script>

<style>

</style>
